const theme = {
    colors: {
        black: '#000000',
        white: '#ffffff',
        gray: '#909090',
        lightGray: '#F1F1F1',
        orange: '#EA6D2B',
        red: '#be3535',
        green: '#0a8700',
    },
    breakLines: {
        desktopMax: '1200px',
        desktopMin: '1199px',
        tabletMax: '992px',
        tabletMin: '991px',
        mobileMax: '768px',
        mobileMin: '767px',
        xMobileMax: '481px',
        xMobileMin: '480px',
    },
};

export default theme;
