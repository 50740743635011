import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'react-scroll';

import Container from '../components/Container';
import Button from "../components/Button";

const SectionWrapper = styled.section`
    display: grid;
    position: relative;
    height: 64rem;
    
    .gatsby-image-wrapper {
        grid-area: 1/1;
        
        img {
            filter: brightness(50%) contrast(120%);
        }
    }
`;

const ContentContainer = styled(Container)`
    grid-area: 1/1;
    position: relative;
    justify-content: center;
`;

const Title = styled.h1`
    font-weight: 700;
    font-size: 4.6rem;
    text-align: center;
    margin: 4rem 0 5rem;
    color: ${props => props.theme.colors.white};

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        font-size: 2.6rem;
    }

    strong {
        color: ${props => props.theme.colors.orange};
    }
`;

const SubTitle = styled.h2`
    font-weight: 700;
    font-size: 2.9rem;
    text-align: center;
    color: ${props => props.theme.colors.white};

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        font-size: 1.8rem;
    }
`;


const HeroSection = () => (
    <SectionWrapper>
        <StaticImage
            src="../images/hero-image.jpg"
            alt="Usługi Elektryczne Krystian Zając"
            placeholder="blurred"
            layout="fullWidth"
        />
        <ContentContainer>
            <SubTitle>
                Szukasz najlepszego rozwiązania na ogrzewanie Twojego mieszkania?
                <br/>Poznaj naszą propozycję
            </SubTitle>
            <Title>
                System ogrzewania płaszczyznowego<br/>
                elektrycznymi <strong>foliami grzewczymi</strong>
            </Title>
            <Link to="contact-form" smooth={true} duration={1000}>
                <Button>Wyceń koszty</Button>
            </Link>
        </ContentContainer>
    </SectionWrapper>
);

export default HeroSection;
