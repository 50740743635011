import React from 'react';
import styled from 'styled-components';

import FacebookIcon from '../images/facebook-icon.svg';
import PhoneIcon from '../images/phone-icon.svg';
import EmailIcon from '../images/email-icon.svg';

const SocialWrapper = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        flex-direction: column;
    }
`;

const PhoneNumber = styled.a`
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.orange};
    text-decoration: none;
    font-weight: 500;
    transition: .3s all;

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        font-size: 0;
    }

    &:hover {
        font-weight: 700;
    }
    
    &:before {
        content: '';
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        background-size: cover;
        margin-right: 1rem;
        background-image: url(${PhoneIcon});
        margin-left: 4rem;

        @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
            margin: 1rem 0;
        }
    }
`;

const Email = styled.a`
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.orange};
    text-decoration: none;
    font-weight: 500;
    transition: .3s all;

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        font-size: 0;
    }

    &:hover {
        font-weight: 700;
    }
    
    &:before {
        content: '';
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        background-size: cover;
        margin-right: 1rem;
        background-image: url(${EmailIcon});

        @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
            margin: 1rem 0;
        }
    }
`;

const Facebook = styled.a`
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background-size: cover;
    background-image: url(${FacebookIcon});
    transition: .3s all;
    margin-left: 4rem;

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        margin: 1rem 0;
    }
    
    &:hover {
        transform: scale(1.1);
    }
`;

const Socials = () => (
    <SocialWrapper>
        <Email href="mailto:biuro@uslugielektryczne-krystianzajac.pl">biuro@uslugielektryczne-krystianzajac.pl</Email>
        <PhoneNumber href="tel:664425152">+48 664 425 152</PhoneNumber>
        <Facebook href="https://www.facebook.com/Najlepsze.Uslugi.Elektryczne/" target="_blank"/>
    </SocialWrapper>
);

export default Socials;
