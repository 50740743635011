import styled from 'styled-components';

const Title = styled.h2`
    font-size: 3.6rem;
    padding: 7rem 0;
    width: 100%;
    font-weight: 700;
    line-height: 1.3;
    text-align: ${(props) => props.center ? 'center' : 'left'};
    color: ${props => props.theme.colors.black};

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        font-size: 2rem;
        padding: 4rem 0 2rem;
    }
    
    strong {
        color: ${props => props.theme.colors.orange};
    }
`;

export default Title;
