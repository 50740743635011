function useAdvices() {
    return [
        'Brak potrzeby ruchu powietrza do prawidłowego działania ogrzewania',
        'Równomierne grzanie',
        'Szybka i „czysta” instalacja (1-3 godzin na pomieszczenie)',
        'Precyzyjna regulacja temperatury i możliwość sterowania przez systemy Smart bez drogich dodatkowych urządzeń',
        'Niskie koszty instalacji i eksploatacji w stosunku do innych systemów grzewczych',
        'Bezobsługowość'
    ];
}

export default useAdvices;
