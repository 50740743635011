import { useState } from 'react';
import * as Yup from 'yup';

function useContactForm() {
    const [ notification , setNotification ] = useState({});
    const [ verified, setVerified ] = useState(false);

    const initialValues = {
        name: '',
        email: '',
        phone: '',
        city: '',
        meters: '',
        agreements: false,
        subject: 'Nowe zapytanie o folie grzewcze od YOUR_FORM_NAME',
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Za mało znaków!').max(50, 'Za dużo znaków (max. 50)!').required('Pole jest wymagane!'),
        city: Yup.string().required('Pole jest wymagane!'),
        phone: Yup.string().required('Pole jest wymagane!'),
        email: Yup.string().email('Nieprawidłowy email').required('Pole jest wymagane!'),
        meters: Yup.string().required('Pole jest wymagane!'),
        agreements: Yup.boolean().oneOf([true], 'Musisz zaakceptować zgody!'),
    });

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    function onSubmit(values, actions) {
        setNotification({});

        if(!verified) {
            setNotification({
                type: 'error',
                message: `Błąd! Nie zweryfikowano reCaptchy! Kliknij "Nie jestem robotem"`,
            });
            return;
        }

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact-form", ...values }),
        })
        .then(function (response) {
            setNotification({
                type: 'succes',
                message: `Gratulacje! Twoje zapytanie zostało wysłane!`,
            });
            setTimeout(() => {
                setNotification({});
            }, 5000);
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(() => {
            actions.resetForm();
            actions.setSubmitting(false);
        });
    }

    function verify() {
        setNotification({});
        setVerified(true);
    }

    function expired() {
        setNotification({});
        setVerified(false);
    }

    return [
        initialValues,
        validationSchema,
        notification,
        verify,
        expired,
        onSubmit
    ];
}

export default useContactForm;
