import React from 'react';
import styled from 'styled-components';

import Container from '../components/Container';
import Title from '../components/Title';

import ChatIcon from '../images/chat-icon.svg';
import useAdvices from '../hooks/useAdvices';

const SectionWrapper = styled.section`
    margin: 0 0 14rem 0;
`;

const AdvicesList = styled.ul`
    display: grid;
    margin: 0 auto;
    padding: 0;
    max-width: 120rem;

    @media (min-width: ${(props) => props.theme.breakLines.desktopMax}) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 21rem 21rem;
        gap: 6rem 7rem;
    }

    @media (max-width: ${(props) => props.theme.breakLines.desktopMin}) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
        gap: 6rem 7rem;
        max-width: 90rem;
    }

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        gap: 3rem 2rem;
    }
`;

const AdvicesItem = styled.li`
    background: ${props => props.theme.colors.white};
    border: .2rem solid ${props => props.theme.colors.gray};
    box-sizing: border-box;
    box-shadow: -.8rem .8rem 3.4rem rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        padding: 2rem 1rem;
    }
`;

const AdvicesIcon = styled.img`
    margin-bottom: 2rem;
`;

const AdvicesDesc = styled.p`
    font-size: 1.8rem;
    line-height: 2.8rem;
    letter-spacing: .1rem;
    text-align: center;
    font-weight: 500;
    color: ${props => props.theme.colors.gray};

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        font-size: 1.2rem;
        line-height: 1.2;
    }
`;

const AdvicesSection = () => {
    const advices = useAdvices();

    return (
        <SectionWrapper>
            <Container>
                <Title center>
                    Zalety ogrzewania powierzchniowego<br/>
                    montowanego przez naszą <strong>firmę</strong>
                </Title>
                <AdvicesList>
                    {advices.map((item, index) => (
                        <AdvicesItem key={index}>
                            <AdvicesIcon src={ChatIcon} alt="Chat Icon"/>
                            <AdvicesDesc>
                                {item}
                            </AdvicesDesc>
                        </AdvicesItem>
                    ))}
                </AdvicesList>
            </Container>
        </SectionWrapper>
    );
};

export default AdvicesSection;
