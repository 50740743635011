import React from 'react';

import Layout from '../layouts/default';

import Seo from '../components/Seo';
import Hero from '../components/Hero';

import AdvicesSection from "../sections/AdvicesSection";
import ContentSection from "../sections/ContentSection";
import ContactSection from "../sections/ContactSection";

const IndexPage = () => {
    return (
        <Layout>
            <Seo title="Ogrzewanie Płaszczyznowe"/>
            <Hero/>
            <AdvicesSection/>
            <ContentSection/>
            <ContactSection/>
        </Layout>
    );
};

export default IndexPage;
