import styled from 'styled-components';

const Container = styled.div`
    box-sizing: border-box;
    max-width: 144rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding: 0 2rem;
    flex-flow: column nowrap;
    align-items: center;
`;

export default Container;
