import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';

const Wrapper = styled.div`
    padding: 0 2rem;
    position: relative;
    grid-column: 1 / span 2;
    display: flex;
`;

const Label = styled.label`
    color: ${(props) => props.theme.colors.gray};
    transition: all 200ms ease-out;
    z-index: 10;
    line-height: 1.4;
    cursor: pointer;
    font-size: 1.2rem;
    margin-left: 1rem;
    letter-spacing: .05rem;

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        font-size: 1rem;
    }
`;

const Input = styled.input`
    display: grid;
    place-content: center;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: ${(props) => props.theme.colors.orange};
    width: 4.44rem;
    height: 1.6rem;
    border: 2px solid ${(props) => props.theme.colors.orange};
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        width: 9.44rem;
    }
    
    &::before {
        content: '';
        width: 0.6em;
        height: 0.65em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em ${(props) => props.theme.colors.orange};

        @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
            width: 0.65em;
            height: 0.em;
        }
    }

    &:checked::before {
        transform: scale(1);
    }
`;

const Error = styled.span`
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    color: red;
    font-size: 1.2rem;
    padding: .5rem 1rem;
`;

const CheckboxField = ({label, children, ...props}) => {
    const [field, meta] = useField(props);
    return (
        <Wrapper>
            <Input {...field} {...props} id={props.name} type="checkbox" />
            <Label htmlFor={props.name}>{children}</Label>
            {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
        </Wrapper>
    );
};

export default CheckboxField;
