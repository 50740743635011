import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import Container from '../components/Container';

import Title from '../components/Title';

const SectionWrapper = styled.section`
    margin: 14rem 0;
`;

const Row = styled.article`
    display: flex;
    gap: 7rem;

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        flex-direction: ${props => props.mobileReverse ? 'column-reverse' : 'column'};
        gap: 3rem;
    }
    
    & + & {
        margin-top: 14rem;

        @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
            margin-top: 10rem;
        }
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        width: 100%;
        
        .gatsby-image-wrapper {
            max-width: 80%;
            margin: 0 auto;
        }
    }
    
    ${Title} {
        padding-top: 0;
    }
`;

const Desc = styled.p`
    font-size: 1.8rem;
    line-height: 2.8rem;
    letter-spacing: .1rem;
    text-align: left;
    font-weight: 400;
    color: ${props => props.theme.colors.gray};

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        font-size: 1.2rem;
        line-height: 2rem;
    }
`;

const AdvicesSection = () => {
    return (
        <SectionWrapper>
            <Container>
                <Row>
                    <Column>
                        <StaticImage
                            src="../images/folia-image.png"
                            alt="Folia Grzewcza Heat-On"
                            placeholder="blurred"/>
                    </Column>
                    <Column>
                        <Title center>
                            Jak działa ogrzewanie <strong>folią</strong>?
                        </Title>
                        <Desc>
                            Działanie folii grzewczych oparte jest o emisję  promieniowania podczerwonego, przypomina odczuwanie ciepła generowanego przez słońce. Folie grzewcze to alternatywny i ekonomiczny system ogrzewania powierzchniowego z możliwością instalacji jako ogrzewanie podłogowe, ścienne lub sufitowe.
                            <br/><br/>
                            Folie grzewcze zapewniają równomierny rozkład ogrzewania na całej powierzchni. Bardzo dobre właściwości izolacji elektrycznej gwarantują całkowite bezpieczeństwo użytkowania.
                        </Desc>
                    </Column>
                </Row>
                <Row mobileReverse>
                    <Column>
                        <Title center>
                            Ogrzewanie <strong>podłogowe</strong>
                        </Title>
                        <Desc>
                            Folię grzewczą Heat-On stosujemy bezpośrednio pomiędzy podkład izolująco-wygłuszający i panel podłogowy
                            <br/><br/>
                            Ze względu na niewielką grubość folii można ją instalować bez konieczności likwidowania istniejącej powłoki podłogi (np. bezpośrednio na istniejące płytki).
                            <br/><br/>
                            Dokładne ułożenie folii pod podłogą zapewni prawidłowe rozprowadzenie ciepła, równomierne jego odczuwanie podczas przemieszczania się oraz ciepłą podłogę na całej powierzchni, którą lubimy najbardziej.
                        </Desc>
                    </Column>
                    <Column>
                        <StaticImage
                            src="../images/floor-image.png"
                            alt="Folia Grzewcza Heat-On na podłodze"
                            placeholder="blurred"/>
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <StaticImage
                            src="../images/sufit-image.png"
                            alt="Folia Grzewcza Heat-On na suficie"
                            placeholder="blurred"/>
                    </Column>
                    <Column>
                        <Title center>
                            Ogrzewanie <strong>sufitowe</strong>
                        </Title>
                        <Desc>
                            System ogrzewania Heat-On jest instalowany pomiędzy konstrukcjami nośnymi sufitu gipsowo-kartonowego i ściśle do niego przylega, by najpełniej oddawać ciepło w pomieszczeniu. Ze względu na wyższą moc folii sufitowej, musimy zapełnić min. 60% jego powierzchni, przez co zostanie również dużo miejsca na montaż oświetlenia ozdobnego i punktowego.
                        </Desc>
                    </Column>
                </Row>
            </Container>
        </SectionWrapper>
    );
};

export default AdvicesSection;
