import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import GlobalContainer from './Container';

import { StaticImage } from "gatsby-plugin-image";
import Socials from "./Socials";

const HeaderContainer = styled.header`
    
`;

const Container = styled(GlobalContainer)`
    justify-content: space-between;
    flex-direction: row;
    padding: 1rem 2rem;
`;

const LogoWrapper = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${props => props.theme.colors.black};

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        font-size: 1.8rem;
        
        .gatsby-image-wrapper {
            width: 7rem;
            height: 7rem;
        }
    }
`;

const HeaderLogoTitle = styled.span`
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.2;
    margin-left: 2rem;

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        font-size: 1.8rem;
    }
    
    strong {
        color: ${props => props.theme.colors.orange};
        font-weight: 500;
    }
`;

const Header = () => (
    <HeaderContainer>
        <Container>
            <LogoWrapper to="/">
                <StaticImage
                    src="../images/logo.png"
                    alt="Usługi Elektryczne Krystian Zając"
                    placeholder="blurred"
                    width={90}
                    height={90}
                />
                <HeaderLogoTitle>
                    Usługi elektryczne<br/><strong>Krystian Zając</strong>
                </HeaderLogoTitle>
            </LogoWrapper>
            <Socials/>
        </Container>
    </HeaderContainer>
);

export default Header;
