import React from 'react';
import styled, { css } from 'styled-components';
import { useField } from 'formik';

const Wrapper = styled.div`
    position: relative;
    grid-column: ${(props) => (props.fullWidth ? '1 / span 2' : 'auto')};
`;

const Label = styled.label`
    position: absolute;
    top: 0;
    left: 2rem;
    height: 6rem;
    line-height: 6rem;
    color: ${(props) => props.theme.colors.gray};
    cursor: text;
    transition: all 200ms ease-out;
    z-index: 10;
    pointer-events: none;
    font-size: 1.8rem;
`;

const Input = styled.input`
    width: 100%;
    height: 6rem;
    box-sizing: border-box;
    line-height: 3rem;
    border: 2px solid ${(props) => props.theme.colors.orange};
    outline: none;
    border-radius: .5rem;
    padding: 0 2rem;
    font-size: 1.8rem;
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.white};
    -webkit-appearance: none;

    ${(props) =>
            props.value &&
            css`
                & ~ ${Label} {
                    color: ${(props) => props.theme.colors.text};
                    transform: translateY(-2rem);
                    font-size: 1rem;
                    cursor: default;
                }
            `}
    &:focus {
        & ~ ${Label} {
            color: ${(props) => props.theme.colors.text};
            transform: translateY(-2rem);
            font-size: 1rem;
            cursor: default;
        }
    }

    &:autofill,
    &:-webkit-autofill {
        background-color: ${(props) => props.theme.colors.white} !important;
    }
`;

const Error = styled.span`
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    color: red;
    font-size: 1.2rem;
    padding: .5rem 1rem;
`;

const TextField = ({label, fullWidth, ...props}) => {
    const [field, meta] = useField(props);
    return (
        <Wrapper fullWidth={fullWidth}>
            <Input {...field} {...props} id={props.name} />
            <Label htmlFor={props.name}>{label}</Label>
            {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
        </Wrapper>
    );
};

export default TextField;
