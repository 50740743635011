import React from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import Recaptcha from 'react-recaptcha';

import TextField from '../components/TextField';
import CheckboxField from '../components/CheckboxField';
import Button from '../components/Button';

import Container from '../components/Container';
import Title from '../components/Title';

import useContactForm from '../hooks/useContactForm';

const SectionWrapper = styled.section`
    background-color: ${props => props.theme.colors.lightGray};
    margin: 7rem 0 0 0;
    padding-bottom: 7rem;
`;

const FormWrapper = styled.div`
    max-width: 100%;
    margin: 0 auto;

    @media (min-width: ${(props) => props.theme.breakLines.mobileMax}) {
        padding: 6rem 0;
        grid-gap: 4rem;
        width: 76rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 6rem 6rem 6rem 10rem auto;
    }

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }
`;

const SubmitWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    grid-column: 1 / span 2;

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        grid-column: auto;
    }
`;

const Notification = styled.p`
    position: absolute;
    top: 100%;
    margin-top: 4rem;
    font-size: 2.2rem;
    right: 2rem;
    font-weight: 600;
    ${(props) => props.color === 'succes' && `color: ${props.theme.colors.green};`}
    ${(props) => props.color === 'error' && `color: ${props.theme.colors.red};`}
`;

const ContactSection = () => {
    const [
        initialValues,
        validationSchema,
        notification,
        verify,
        expired,
        onSubmit
    ] = useContactForm();

    return (
        <SectionWrapper id="contact-form">
            <Container>
                <Title center>
                    Wyceń koszty ogrzewania dla twojego mieszkania<br/>
                    Wypełnij formularz
                </Title>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    <Form name="contact-form" data-netlify={true}>
                        <FormWrapper>
                            <TextField name="name" type="text" label="Imię i nazwisko*" fullWidth/>
                            <TextField name="email" type="text" label="Adres e-mail*"/>
                            <TextField name="phone" type="text" label="Numer telefonu*"/>
                            <TextField name="city" type="text" label="Miejscowość*"/>
                            <TextField name="meters" type="text" label="Metraż w m2*"/>
                            <CheckboxField name="agreements">
                                Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem poinformowany, że przysługuje mi prawo dostępu do swoich danych, możliwość ich poprawiania, żądania zaprzestania ich przetwarzania. Administratorem danych osobowych jest Usługi Elektryczne Krystian Zając, Rzędowice, Dolna 1 46-380 Dobrodzień.
                            </CheckboxField>
                            <SubmitWrapper>
                                <Recaptcha
                                    sitekey="6LeQz4IeAAAAACVbZeSGdqmnvq2TcXVgN1r4hOgC"
                                    render="explicit"
                                    hl="pl"
                                    verifyCallback={verify}
                                    expiredCallback={expired}
                                />
                                {notification &&
                                    <Notification color={notification.type}>
                                        {notification.message}
                                    </Notification>
                                }
                                <Button wide type="submit">Wyślij</Button>
                            </SubmitWrapper>
                        </FormWrapper>
                    </Form>
                </Formik>
            </Container>
        </SectionWrapper>
    );
};

export default ContactSection;
