import React from 'react';
import styled from 'styled-components';

import GlobalContainer from './Container';

import Socials from './Socials';

const FooterContainer = styled.footer`
    background-color: ${props => props.theme.colors.white};
`;

const Container = styled(GlobalContainer)`
    justify-content: space-between;
    flex-direction: row;
    padding: 4rem 2rem;

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        padding: 1rem 2rem;
    }
`;

const Copyright = styled.p`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${props => props.theme.colors.black};

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        font-size: 1.2rem;
        max-width: 18rem;
        line-height: 1.4;
    }
`;

const Header = () => (
    <FooterContainer>
        <Container>
            <Copyright>
                Prawa autorskie © 2022 Usługi elektryczne Krystian Zając. Wszelkie prawa zastrzeżone
            </Copyright>
            <Socials/>
        </Container>
    </FooterContainer>
);

export default Header;
