import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import GlobalStyles from '../components/GlobalStyles';
import theme from '../utils/theme';
import Header from '../components/Header';
import Footer from '../components/Footer';

const LayoutContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
`;

const Main = styled.main`
    flex: 1;
`;

function Layout({children}) {
    return (
        <ThemeProvider theme={theme}>
            <LayoutContainer>
                <GlobalStyles/>
                <Header/>
                <Main>
                    {children}
                </Main>
                <Footer/>
            </LayoutContainer>
        </ThemeProvider>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
