import React from 'react';
import styled from 'styled-components';

const ButtonText = styled.span`
    position: relative;
    color: transparent;
    background-image: linear-gradient(90deg,
    ${(props) => props.theme.colors.orange} 0%,
    ${(props) => props.theme.colors.orange} 50%,
    ${(props) => props.theme.colors.white} 50%,
    ${(props) => props.theme.colors.white} 100%);
    background-repeat: repeat;
    background-size: 200%;
    background-position: 100% 0;
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: 700;
    font-size: 2.2rem;
    margin-bottom: 0;
    transition: background-position 300ms;
    font-family: 'Roboto', sans-serif;

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        font-size: 1.8rem;
    }

    &:after {
        content: '\\279C';
        font-weight: 400;
        font-size: 1.8rem;
        margin-left: .5rem;
    }
`;

const ButtonWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 5.5rem;
    width: ${(props) => props.wide ? '45rem' : '26rem'};
    font-size: 2.2rem;
    cursor: pointer;
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    overflow: hidden;
    max-width: 100%;
    border: .3rem solid ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.orange};
    transition: .3s all;

    @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
        height: 4.5rem;
        border-radius: 1rem;
        width: ${(props) => props.wide ? '33rem' : '26rem'};
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${(props) => props.theme.colors.white};
        transform-origin: 100% 0;
        transform: scale3d(0, 1, 1);
        transition: transform 300ms;
    }

    &:focus {
        border-radius: 2rem;
        outline: none;

        @media (max-width: ${(props) => props.theme.breakLines.mobileMin}) {
            border-radius: 1rem;
        }
    }

    &:hover {
        border: .3rem solid ${(props) => props.theme.colors.orange};
        
        ${ButtonText} {
            background-position: 0 0;
        }

        &::before {
            transform-origin: 0 0;
            transform: scale3d(1, 1, 1);
        }
    }
`;

const Button = ({type, wide, children, onClick}) => (
    <ButtonWrapper type={type} wide={wide} onClick={onClick}>
        <ButtonText>{children}</ButtonText>
    </ButtonWrapper>
);

export default Button;
